import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "button", "form" ]

  connect() {
    this.initMobbexButton()
  }

  initMobbexButton() {
    const self = this;

    let script = document.createElement("script");
    
    script.src = `https://res.mobbex.com/js/embed/mobbex.embed@1.0.18.js?t=${Date.now()}`;
    script.async = true;
    script.crossorigin = "anonymous";
    
    script.addEventListener("load", () => {
      self.renderMobbexButton()
    });
    
    document.body.appendChild(script); 
  }

  renderMobbexButton() {
    const self = this; 

    if (!self.hasButtonTarget){
      return false;
    }

    const buttonText = self.buttonTarget.getAttribute('data-checkout-text');

    var options = {
      id: self.buttonTarget.getAttribute('data-checkout-id'),
      type: "checkout",
      button:{
        text: buttonText,
        backgroundColor: "#3ad0a7",
        textColor: "rgb(255, 255, 255)",
        textSize: "0.875rem", 
        padding: "0.375rem 0.75rem"   
      },
      onResult: (response) => {
          window.MobbexEmbed.close();
      },
      onPayment: (response) => {
        if (response.data.status.code == '200'){
          const transactionId = response.data.id;
          const transactionStatus = 'approved';
          const transactionType = 'card';

          window.document.getElementById('checkout_transaction_id').value = transactionId;
          window.document.getElementById('checkout_transaction_status').value = transactionStatus;
          window.document.getElementById('checkout_transaction_type').value = transactionType;

          self.formTarget.submit();
        }else{
          alert('Hubo un problema con tu pago, por favor refrescá la página e intentá de nuevo. Gracias!');
        }
      },
      onOpen: () => {
          console.info("Pago iniciado.");
          $('.mobbex-embed-fw-close').show();
      },
      onError: (error) => {
        console.error("ERROR: ", error);
        
        alert('Hubo un problema con tu pago, por favor refrescá la página e intentá de nuevo. Gracias!');
      }
    };  

    window.MobbexEmbed.render(options, "#mbbx-button");
    
    $("#mbbx-button > div > div > div").attr('style', '');
    $("#mbbx-button > div > div > div").addClass('btn btn-primary btn-block btn-lg');
    $("#mbbx-button > div > div > div").html("<i class=\"fa fa-credit-card\"></i> " + buttonText)
  }   

}