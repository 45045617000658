import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "select" ]

  populate_localities(event) {
    const parent_id = event.currentTarget.value;
    const currentTarget = event.currentTarget;

    $.ajax({
      url: '/states/' + parent_id + '/localities',
      dataType: 'json',
      success: function(data) {
        var childElement = $(currentTarget.getAttribute('data-target'));

        childElement.html('');

        data.forEach(function(v) {
          var id = v.id;
          var name = v.name;
          childElement.append('<option value="' + id + '">' + name + '</option>');
        });      
      }
    });    
  }
}