require('select2');

import { Controller } from 'stimulus'
import { useDispatch } from 'stimulus-use'

export default class extends Controller {

  static targets = [ "select" ]

  connect() {
    this.initSelect2()
    useDispatch(this)
  }

  disconnect() {
    this.destroySelect2()
    useDispatch(this)
  }

  initSelect2() {
    if (!this.hasSelectTarget){ return; }

    const self = this;

    $(this.selectTarget).select2(
      {
        theme: 'bootstrap4',
      }
    ).on('select2:select', function(e){
      var currentValue = $(e.currentTarget).val();
      var childElement = $(self.selectTarget.getAttribute('data-target'));
      var model = self.selectTarget.getAttribute('data-model');

      self.onSelect(model, currentValue, childElement);
    });
  }

  destroySelect2() {
    if (!this.hasSelectTarget){ return; }

    $(this.selectTarget).select2('destroy');
  }

  onSelect(model, id, childElement) {
    if (model == 'state'){
      $.ajax({
        url: '/states/' + id + '/localities',
        dataType: 'json',
        success: function(data) {

          childElement.html('');

          data.forEach(function(v) {
            var id = v.id;
            var name = v.name;
            childElement.append('<option value="' + id + '">' + name + '</option>');
          });
        }
      });
    }else if (model == 'checkOutAddressState'){
      this.formSubmit();
    }
  }

  formSubmit() {
    this.dispatch("formSubmit");
  }

}
