import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String };

  open(event) {
    event.preventDefault();
    
    fetch(this.urlValue)
    .then((r) => r.text())
    .then((html) => {
      $("#modal-window").find(".modal-content").html(html);
      $("#modal-window").modal();      
    });    
  }
}