import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "select" ]

  populate_subcategories(event) {
    const parent_id = event.currentTarget.value;
    const currentTarget = event.currentTarget;

    $.ajax({
      url: '/categorias/children?category_id=' + parent_id,
      dataType: 'json',
      success: function(data) {
        var childElement = $(currentTarget.getAttribute('data-target'));

        childElement.html('');
        data.forEach(function(v) {
          var id = v.id;
          var name = v.name;
          childElement.append('<option value="' + id + '">' + name + '</option>');
        });
      }
    });    
  }
}