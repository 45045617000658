import { Controller } from 'stimulus'
import '../utils/multiselect/multiselect.bundle.js'

export default class extends Controller {

  static targets = [ "select" ]

  connect() {
    this.initMultiselect()
  }

  initMultiselect() {
    $(this.selectTarget).multiselect();
  }

}