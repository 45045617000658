import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "input", "hero" ]

  readUrl(event) {
    const input = event.currentTarget

    if (input.files && input.files[0]) {
      const reader = new FileReader();
      const jumbo = this.heroTarget;

      reader.onload = function(e) {
        const height = jumbo.getAttribute('data-height') || "140px";

        jumbo.setAttribute('style', 'background-position: center; background-size: cover; background-repeat: no-repeat; background-image: url(' + e.target.result + '); height: ' + height + ';');
      }
      
      reader.readAsDataURL(input.files[0]);
    }
  }

}