import ahoy from "ahoy.js";

ahoy.configure({
  urlPrefix: "",
  visitsUrl: "/track/visits",
  eventsUrl: "/track/events",
  page: null,
  platform: "Web",
  useBeacon: true,
  startOnReady: true,
  trackVisits: true,
  cookies: true,
  cookieDomain: "ferialocal.com",
  headers: {},
  visitParams: {},
  withCredentials: false,
  visitDuration: 4 * 60, // 4 hours
  visitorDuration: 2 * 365 * 24 * 60 // 2 years
});

ahoy.trackAll();