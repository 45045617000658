import { Controller } from 'stimulus'
import Masonry from 'masonry-layout'

export default class extends Controller {

  static targets = [ "img" ]

  initialize() {
    this.initMasonry()    
  }

  connect() {
    this.lazyLoad()    
  }

  disconnect() {
    if (this.msnry){
      this.msnry.reloadItems()
      this.msnry.layout()
    }    
  }

  initMasonry() {
    const elem = document.querySelector('.grid')

    this.msnry = new Masonry( elem, {
      itemSelector: '.grid-item',
      isAnimated: true
    });
  }

  lazyLoad() {
    let imgLarge = new Image()
    let imageOriginal = this.imgTarget.querySelector('img')

    const self = this

    imgLarge.src = this.imgTarget.getAttribute("data-large")
    
    imgLarge.onload = function () {
      imageOriginal.src = imgLarge.src
      imageOriginal.classList.add('img-loaded')
      self.msnry.layout()
    };
  }

}