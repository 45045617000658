import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["card"];
  static values = { url: String };

  connect() {
    this.hovered = false;
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
      this.loading = false;
      this.hovered = false;
    }
  }  

  show() {
    const self = this
    
    self.hovered = true;

    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("d-none");
    } else {
      if (!self.loading){
        self.loading = true

        fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => {
          if (self.hovered){
            const fragment = document
            .createRange()
            .createContextualFragment(html)

          this.element.appendChild(fragment)
          }
          
          self.loading = false
        });
      }
    }
  }

  hide() {
    this.hovered = false;

    if (this.hasCardTarget) {
      this.cardTarget.classList.add("d-none");
    }
  }
}