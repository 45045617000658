import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["hiddenLocation", "visibleLocation", "moreButton", "lessButton"]
  
  lines = 4

  connect() {
    this.show(this.moreButtonTarget);
  }

  showMore() {
    this.hiddenLocationTargets.forEach(function(e) {
      e.classList.remove('d-none');
    });

    this.hide(this.moreButtonTarget);
    this.show(this.lessButtonTarget);
  }

  showLess() {
    this.hiddenLocationTargets.forEach(function(e) {
      e.classList.add('d-none');
    });    

    this.hide(this.lessButtonTarget);
    this.show(this.moreButtonTarget);
  }

  show(target) {
    target.classList.remove("d-none")
  }

  hide(target) {
    target.classList.add("d-none")
  }
}