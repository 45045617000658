
import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      draggable: '.draggable',
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    const image_sort_container = document.getElementById('image_sorts');
          image_sort_container.innerHTML = "";

    let id = event.item.dataset.id
    let image_container_elements = this.element.getElementsByClassName('product-image-container');
    let image_container_array = Array.from(image_container_elements);
    let ordered_images = image_container_array.map(o => o.getAttribute('data-id'))    

    for (const image_id of ordered_images){      
      let input = document.createElement("input");
          input.name = "product[image_sort][]";
          input.type = "text";
          input.setAttribute("value", image_id.toString());
      
      image_sort_container.append(input);
    }

  }
}