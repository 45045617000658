import { Controller } from "stimulus"
import { DirectUpload } from "activestorage"

const ExifRestorer = (function()
{

  var ExifRestorer = {};

  ExifRestorer.KEY_STR = "ABCDEFGHIJKLMNOP" +
  "QRSTUVWXYZabcdef" +
  "ghijklmnopqrstuv" +
  "wxyz0123456789+/" +
  "=";

  ExifRestorer.encode64 = function(input)
  {
    var output = "",
    chr1, chr2, chr3 = "",
    enc1, enc2, enc3, enc4 = "",
    i = 0;

    do {
      chr1 = input[i++];
      chr2 = input[i++];
      chr3 = input[i++];

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
       enc3 = enc4 = 64;
     } else if (isNaN(chr3)) {
       enc4 = 64;
     }

     output = output +
     this.KEY_STR.charAt(enc1) +
     this.KEY_STR.charAt(enc2) +
     this.KEY_STR.charAt(enc3) +
     this.KEY_STR.charAt(enc4);
     chr1 = chr2 = chr3 = "";
     enc1 = enc2 = enc3 = enc4 = "";
   } while (i < input.length);

   return output;
  };

  ExifRestorer.restore = function(origFileBase64, resizedFileBase64)
  {
  if (!origFileBase64.match("data:image/jpeg;base64,"))
  {
    return resizedFileBase64;
  }

  var rawImage = this.decode64(origFileBase64.replace("data:image/jpeg;base64,", ""));
  var segments = this.slice2Segments(rawImage);

  var image = this.exifManipulation(resizedFileBase64, segments);

  return this.encode64(image);

  };


  ExifRestorer.exifManipulation = function(resizedFileBase64, segments)
  {
  var exifArray = this.getExifArray(segments),
  newImageArray = this.insertExif(resizedFileBase64, exifArray),
  aBuffer = new Uint8Array(newImageArray);

  return aBuffer;
  };


  ExifRestorer.getExifArray = function(segments)
  {
    var seg;
    for (var x = 0; x < segments.length; x++)
    {
      seg = segments[x];
        if (seg[0] == 255 & seg[1] == 225) //(ff e1)
        {
          return seg;
        }
      }
      return [];
  };


  ExifRestorer.insertExif = function(resizedFileBase64, exifArray)
  {
    var imageData = resizedFileBase64.replace("data:image/jpeg;base64,", ""),
    buf = this.decode64(imageData),
    separatePoint = buf.indexOf(255,3),
    mae = buf.slice(0, separatePoint),
    ato = buf.slice(separatePoint),
    array = mae;

    array = array.concat(exifArray);
    array = array.concat(ato);
    return array;
  };



  ExifRestorer.slice2Segments = function(rawImageArray)
  {
    var head = 0,
    segments = [];

    while (1)
    {
      if (rawImageArray[head] == 255 & rawImageArray[head + 1] == 218){break;}
      if (rawImageArray[head] == 255 & rawImageArray[head + 1] == 216)
      {
        head += 2;
      }
      else
      {
        var length = rawImageArray[head + 2] * 256 + rawImageArray[head + 3],
        endPoint = head + length + 2,
        seg = rawImageArray.slice(head, endPoint);
        segments.push(seg);
        head = endPoint;
      }
      if (head > rawImageArray.length){break;}
    }

    return segments;
  };



  ExifRestorer.decode64 = function(input)
  {
    var output = "",
    chr1, chr2, chr3 = "",
    enc1, enc2, enc3, enc4 = "",
    i = 0,
    buf = [];

      // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
      var base64test = /[^A-Za-z0-9\+\/\=]/g;
      if (base64test.exec(input)) {
        alert("There were invalid base64 characters in the input text.\n" +
          "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
          "Expect errors in decoding.");
      }
      input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

      do {
        enc1 = this.KEY_STR.indexOf(input.charAt(i++));
        enc2 = this.KEY_STR.indexOf(input.charAt(i++));
        enc3 = this.KEY_STR.indexOf(input.charAt(i++));
        enc4 = this.KEY_STR.indexOf(input.charAt(i++));

        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;

        buf.push(chr1);

        if (enc3 != 64) {
         buf.push(chr2);
       }
       if (enc4 != 64) {
         buf.push(chr3);
       }

       chr1 = chr2 = chr3 = "";
       enc1 = enc2 = enc3 = enc4 = "";

     } while (i < input.length);

     return buf;
  };


 return ExifRestorer;
})();

const LEAVING_PAGE_MESSAGE = "Se están cargando archivos, si cierras esta ventana puede haber errores irreaparables. Deseas continuar?"

export default class extends Controller {
  static targets = [ "inputFile" ]

  deleteAttachment(event){
    event.preventDefault();

    const img_box = $(event.currentTarget).parent();
    const loading_icon = $('<div>',{ class:'lmask' });
    const csrf_token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    $(event.currentTarget).attr('disabled',true);
    img_box.find('.bounding-box').append(loading_icon);

    $.ajax({
      url: event.currentTarget.getAttribute('href'),
      type: 'DELETE',
      beforeSend: function ( xhr ) {
        xhr.setRequestHeader( 'X-CSRF-Token', csrf_token );
      },
      success: function(result) {
        img_box.remove();
      }
    });
  }

  directUploadsInitialize(event){
    const { target, detail } = event
    const { id, file } = detail

    if(!document.getElementById(`direct-upload-progress-${id}`)){
      document.getElementById('file-upload-progress').insertAdjacentHTML("beforebegin", `
        <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
          <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
          <span class="direct-upload__filename">${file.name}</span>
        </div>
      `)
    }
  }

  directUploadsProgress(event){
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
  }

  directUploadsStart(event) {
    this.setUploading("true")

    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
  }

  directUploadsEnd(event) {
    this.setUploading("false")

    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")
  }

  directUploadsError(event) {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
  }

  leavingPage(event) {
    if (this.isUploadingFiles()) {
      if (event.type == "turbo:before-visit") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = LEAVING_PAGE_MESSAGE;
        return event.returnValue;
      }
    }
  }

  setUploading(changed) {
    this.data.set("uploading", changed)
  }

  isUploadingFiles() {
    return this.data.get("uploading") == "true";
  }

  dataURLtoBlob(dataurl,filename) {
    if (dataurl.includes(',')){
      dataurl = dataurl.replace(/\s/g, '');
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8arr], {type:mime,filename:filename});
    }else{
      var binary = atob(dataurl.replace(/\s/g, ''));
      var array = [];

      for(var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {type: 'image/jpeg',filename:filename});
    }
  }

  uploadFile = (dataUri, url, name, filename, element,link_to_delete) => {
    let file = this.dataURLtoBlob(dataUri,filename);
        file.name = filename;

    const upload = new DirectUpload(file, url, this);

    console.log('Starting upload to ' + url);

    const loading_icon = $('<div>',{class:'lmask'});

    element.append(loading_icon);

    upload.create((error, blob) => {
      if (error) {
        console.log(error);
        alert('Hubo un error al cargar el archivo, por favor intente nuevamente. Gracias!');
      } else {
        const hiddenField = document.createElement('input');

        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = name;

        element.append(hiddenField);

        link_to_delete.attr('data-blob-id', blob.id);
        link_to_delete.parent().attr('data-id', blob.id);
      }

      element.find('.lmask').fadeOut('fast',function(){
        const image_sort_container = document.getElementById('image_sorts');
        image_sort_container.innerHTML = "";

        let image_container_elements = element.parent().parent().find('.product-image-container');
        let image_container_array = Array.from(image_container_elements);
        let ordered_images = image_container_array.map(o => o.getAttribute('data-id'));

        console.log(ordered_images);

        for (const image_id of ordered_images){
          if(image_id){
            let input = document.createElement("input");
            input.name = "product[image_sort][]";
            input.type = "text";
            input.setAttribute("value", image_id.toString());

            image_sort_container.append(input);
          }
        }

        $(this).remove();
        link_to_delete.show();
      });
    });
  };

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    let percent = ((event.loaded / event.total) * 100).toFixed(1)
    console.log(percent);
  }

  onInputChange(event) {
      const img_container = $(event.target).parents('.gallery').find('.row');
      const add_photo_container = img_container.find('.add-photo-container');
      const input = event.target;
      const url = $(input).attr('data-upload-url');
      const name = $(input).attr('name');
      const self = this;

      if (input.files) {
        const filesAmount = input.files.length;

        $.each(input.files, function (i, v) {
          var oFReader = new FileReader(), rFilter = /^(?:image\/bmp|image\/cis\-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x\-cmu\-raster|image\/x\-cmx|image\/x\-icon|image\/x\-portable\-anymap|image\/x\-portable\-bitmap|image\/x\-portable\-graymap|image\/x\-portable\-pixmap|image\/x\-rgb|image\/x\-xbitmap|image\/x\-xpixmap|image\/x\-xwindowdump)$/i;

          if (!rFilter.test(this.type)) { alert("Debes seleccionar un archivo válido!"); return; }

          oFReader.onload = function(oFREvent) {
            let img = new Image();
                img.src=oFREvent.target.result;

            img.onload=function(){
                const canvas=document.createElement("canvas");
                const ctx=canvas.getContext("2d");

                const MAX_WIDTH = 1200;
                const MAX_HEIGHT = 1200;

                let tempW = img.width;
                let tempH = img.height;

                if (tempW > tempH) {
                    if (tempW > MAX_WIDTH) {
                       tempH *= MAX_WIDTH / tempW;
                       tempW = MAX_WIDTH;
                    }
                } else {
                    if (tempH > MAX_HEIGHT) {
                       tempW *= MAX_HEIGHT / tempH;
                       tempH = MAX_HEIGHT;
                    }
                }
                canvas.width=tempW;
                canvas.height=tempH;

                ctx.drawImage(img,0,0,img.width,img.height,0,0,canvas.width,canvas.height);

                const timestamp = Math.round(new Date().getTime()/1000);
                const file_name = `img-upload-${i+1}-${timestamp}.jpg`;
                const dataUrl = canvas.toDataURL("image/jpeg",0.95);
                const restored_dataUrl = ExifRestorer.restore(img.src, dataUrl);
                const img_class_id = `img-${i+1}`;
                const img_box_container = $('<div>',{class:`col-md-3 col-6 mb-3 product-image-container draggable`});
                const img_box = $('<div>',{'data-input-origin':name,class:`bounding-box ${img_class_id}`});
                const img_link = $('<a>',{'data-toggle':'lightbox', href:dataUrl, 'data-type':'image'});
                const img_delete_link = $('<a>',{'data-action':'delete',class:'btn-remove-absolute btn-remove-absolute btn btn-sm btn-light-grey text-gray delete align-self-center d-flex py-2 border-white',html:"<i class='fa fa-trash'></i>"});
                const img_obj = $('<img>',{src:dataUrl,class:'img-thumbnail'});

                img_delete_link.hide();

                img_box.append(img_obj);

                img_box_container.append(img_delete_link);
                img_box_container.append(img_box).insertBefore(add_photo_container);

                self.uploadFile(restored_dataUrl,url,name,file_name,img_box,img_delete_link);

                img_delete_link.on('click', function(){
                  const link_box = $(this).parent();
                  const loading_icon = $('<div>',{class:'lmask'});
                  const csrf_token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

                  $(this).attr('disabled',true);
                  img_box.find('.bounding-box').append(loading_icon);

                  $.ajax({
                      url: '/blobs/' + $(this).attr('data-blob-id'),
                      type: 'DELETE',
                      beforeSend: function ( xhr ) {
                        xhr.setRequestHeader( 'X-CSRF-Token', csrf_token );
                      },
                      success: function(result) {
                        link_box.remove();
                      }
                  });
                });

                img_link.on('click', function(e){
                  e.preventDefault();
                  $(this).ekkoLightbox();
                });
            }
          };

          oFReader.readAsDataURL(this);
        });
      }
  }

  get authenticity_token() {
    return document.querySelector("meta[name='csrf-token']").getAttribute("content");
  }
}
