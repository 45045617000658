import { Controller } from "stimulus"
import { navigator } from "@hotwired/turbo"

export default class extends Controller {

  static targets = [ "input", "image", "loader" ]

  onSubmitDisableWith(event) {
    const submit_buttons = event.currentTarget.querySelectorAll('button[type="submit"]');

    for ( var i = 0, len = submit_buttons.length; i < len; i++ ) {
      let loading_text = submit_buttons[i].getAttribute('data-disable-with');
      let html = document.createElement('span');

      html.innerHTML = loading_text;

      submit_buttons[i].innerHTML = loading_text;
      submit_buttons[i].disabled = true;

      if (submit_buttons[i].getAttribute('data-close-backdrops')) {
        if (document.querySelector('.modal-backdrop')){
          document.querySelector('.modal-backdrop').remove();
        }

        if (document.querySelector('body').classList) {
          document.querySelector('body').classList.remove('modal-open');
        }
      }
    }
  }

  onEditableClick(event) {
    $(event.currentTarget).hide();
    $(event.currentTarget).parent().find('input').removeClass('d-none').focus();
  }

  onEditableFocus(event) {
    $('.btn-inline-submit').addClass('d-none');
    $(event.currentTarget).closest('.row').find('.btn-inline-submit').removeClass('d-none');
    $(event.currentTarget).closest('.row').find('.link-to-edit').addClass('d-none');
  }

  onDeleteCheckboxChange(event) {
    if ($('input[type="checkbox"].selectable').filter(':checked').length > 0){
      $('.btn-delete-products').removeClass('animated fadeOut d-none').addClass('animated fadeIn');
      $('.btn-delete-products-placeholder').removeClass('animated fadeIn').addClass('animated fadeOut d-none');
    }else{
      $('.btn-delete-products').removeClass('animated fadeIn').addClass('animated fadeOut d-none');
      $('.btn-delete-products-placeholder').removeClass('animated fadeOut d-none').addClass('animated fadeIn');
    }

    $('input[name="product_ids"]').val($('input[type="checkbox"].selectable:checked').map(function() {
      return this.value;
    }).get());
  }

  onShippingMetehodRadioButtonChange(event){
    const el = $(event.currentTarget);
    const name = "cart_option[cart_option_shipping_method_attributes][shipping_method_id]"
    const loader = $('<div class="d-block text-center text-muted"><i class="fa fa-spinner fa-spin fa-2x"></i></div>');

    $("input[type='radio'][name='"+name+"']").not(el).each(function(){
      $(this).attr("checked", false);
    });
  }

  readUrl(event) {
    const input = event.currentTarget;
    const avatarTarget = this.imageTarget;

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = function(e) {
        avatarTarget.src = e.target.result;
        avatarTarget.classList.remove("d-none");

        if (this.hasPlaceholderTarget){
          this.placeholderTarget.remove();
        }
      }

      reader.readAsDataURL(input.files[0]);
    }
  }

  formSubmit(event){
    const form = event.currentTarget.form;
    const loaders = document.getElementsByClassName('request-loader');
    const loaders_static = document.getElementsByClassName('request-loader-static');

    for ( var i = 0, len = loaders.length; i < len; i++ ) {
      loaders[i].classList.remove('d-none');
    }

    for ( var i = 0, len = loaders_static.length; i < len; i++ ) {
      loaders_static[i].classList.add('d-none');
    }

    if($(".form-overlay-loader").length > 0){
      $(".form-overlay-loader").css({'background': 'rgba(255, 255, 255, 0.6)'});
      $(".form-overlay-loader").removeClass('d-none');
      $(".form-overlay-loader").addClass('d-flex');
    }

    setTimeout(function () {
      if (form.requestSubmit) {
        form.requestSubmit();
      } else {
        navigator.submitForm(form)
      }
    }, 850);
  }
}
