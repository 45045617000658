import { Application } from 'stimulus';
import { definitionsFromContext } from "stimulus/webpack-helpers";
import RevealController from 'stimulus-reveal';

import './bootstrap_custom.js';
import '../utils/analytics.js';
import '@hotwired/turbo-rails';

// Stimulus Controllers
const application = Application.start();
const context = require.context("controllers", true, /.js$/);

application.load(definitionsFromContext(context));
application.register('reveal', RevealController);

window.$ = window.jQuery = require('jquery');