import { Controller } from 'stimulus'
import { DirectUpload } from "activestorage"
import Cropper from 'cropperjs'
import ekkoLightbox from "ekko-lightbox"

export default class extends Controller {

  static targets = [ "gallery", "thumbnail" ]

  connect() {
    this.initLightbox()
  }

  initLightbox() {
    const self = this;

    $('[data-toggle="lightbox-editable"]').on('click', function (e) {
      e.preventDefault();
      
      const buttonElement = $(this);
      const img_index = $(this).attr('data-index');
      const form_container = $(this).closest('form.editable-form');
      const formInputName =  $(this).attr('data-field-name');
    
      $(this).ekkoLightbox({
        onShow: function(ev) {

          var htmlhtml ='<button type="button" data-method="zoom" data-option="0.1" class="btn btn-sm"><i class="fa fa-search-plus"></i></button><button type="button" data-method="zoom" data-option="-0.1" class="btn btn-sm"><i class="fa fa-search-minus"></i></button><button type="button" data-method="move" data-option="-5" class="btn btn-sm"><i class="fa fa-arrow-left"></i></button><button type="button" data-method="move" data-option="5" class="btn btn-sm"><i class="fa fa-arrow-right"></i></button><button type="button" data-method="rotate" data-option="15" class="btn btn-sm"><i class="fa fa-redo-alt"></i></button><button type="button" class="btn btn-sm" data-method="rotate" data-option="-15"><i class="fa fa-undo-alt"></i></button><button type="button" class="btn btn-sm" data-method="save"><i class="fa fa-save"></i></button>';
          var toAppend = $(htmlhtml);        
          
          const loading_icon = $('<div>',{class:'lmask'});     
          
          $(ev.target).find('.modal-body').append(loading_icon);        
    
          setTimeout(function() {
            $(ev.target).find('.lmask').remove();
            
            $(ev.target).find('.ekko-lightbox-container').css({maxWidth:'820px',height:'100%'});
            $(ev.target).find('.modal-dialog').css({maxWidth:'820px',maxHeight:'820px'});          
            $(ev.target).find('.modal-footer').append(toAppend);
            $(ev.target).find('.modal-footer').show();             
    
            var croppie_target = $(ev.target).find('.modal-body').find('.ekko-lightbox-container').find('.ekko-lightbox-item.show');
            var image_html = croppie_target.find('img'); 
                image_html.attr('crossorigin');
            var image_src = image_html.attr('src');
                image_html.attr('src',image_src);
    
            const image = image_html[0];
    
            const cropper = new Cropper(image, {
              checkCrossOrigin: false,
              checkOrientation: false,
              dragMode: 'move',
              autoCrop: true,
              autoCropArea: 1,
              aspectRatio: 1/1,
              zoomOnWheel: false,
              crop(event) {
                setTimeout(()=>{
                  /**---------------------------------------------------------------------
                    In order for crossorigin to work we need to add crossorigin attribute 
                    to the dynamically created img element, (See Attachment)
                  ---------------------------------------------------------------------*/
                  let canvas_img = window.document.querySelector('img.cropper-hide');
                  let src = canvas_img.getAttribute('src') + "?crossorigin";
    
                  /*Somehow src needs to be set again in order for crossorigin to work */
                  canvas_img.setAttribute('crossorigin', 'anonymous');                
                  canvas_img.setAttribute('src', src)                
               },100)                
              },
            });  
    
            $(ev.target).find('button[data-method=rotate]').on('click',function(){
              cropper.rotate(parseInt($(this).attr('data-option')));
            });
    
            $(ev.target).find('button[data-method=move]').on('click',function(){
              cropper.move(parseInt($(this).attr('data-option')),0);
            });
    
            $(ev.target).find('button[data-method=zoom]').on('click',function(){
              cropper.zoom(parseFloat($(this).attr('data-option')));
            });          
    
            $(ev.target).find('button[data-method=save]').on('click',function(){
              
              const imageDataUrl = cropper.getCroppedCanvas().toDataURL('image/jpeg');       
              const timestamp = Math.round(new Date().getTime()/1000);
              const file_name = `img-upload-${img_index}-${timestamp}.jpg`
    
              let blob = self.dataURLtoBlob(imageDataUrl, file_name);
              let file = blob;
                  file.name = file_name;
    
              const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');
    
              $(ev.target).find('.modal-body').append(loading_icon);
              
              upload.create((error, blob) => {
                if (error) {
                  alert('Hubo un error al cargar el archivo, por favor intente nuevamente. Gracias!');        
                } else {      
                  const hiddenField = document.createElement('input');
    
                  hiddenField.setAttribute("type", "hidden");
                  hiddenField.setAttribute("value", blob.signed_id);
                  hiddenField.name = formInputName;
                  form_container.append(hiddenField);

                  const image_sort_container = document.getElementById('image_sorts');
                  image_sort_container.innerHTML = "";
          
                  let image_container_elements = buttonElement.closest('.gallery').find('.product-image-container');
                  let image_container_array = Array.from(image_container_elements);
                  let ordered_images = image_container_array.map(o => o.getAttribute('data-id'));
                      ordered_images.unshift(blob.id);
          
                  for (const image_id of ordered_images){
                    if(image_id){
                      let input = document.createElement("input");
                      input.name = "product[image_sort][]";
                      input.type = "text";
                      input.setAttribute("value", image_id.toString());
                  
                      image_sort_container.append(input);
                    }
                  }                  
                  
                  form_container.find('.btn-last').trigger('click');                  
                  $(ev.target).modal('hide');

                }     
              });                      
            });            
          }, 5000);
        }     
      });
    }); 
  }

  dataURLtoBlob(dataurl, filename) {
    if (dataurl.includes(',')){
      dataurl = dataurl.replace(/\s/g, '');
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
  
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
  
      return new Blob([u8arr], {type:mime,filename:filename});
    }else{
      var binary = atob(dataurl.replace(/\s/g, ''));
      var array = [];
  
      for(var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {type: 'image/jpeg',filename:filename});
    }    
  }

}