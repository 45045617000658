import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "img" ]

  connect() {
    this.lazyLoad()    
  }

  lazyLoad() {
    let imgLarge = new Image()
    let imageOriginal = this.imgTarget.querySelector('img')

    const self = this

    imgLarge.src = this.imgTarget.getAttribute("data-large")
    
    imgLarge.onload = function () {
      imageOriginal.src = imgLarge.src
      imageOriginal.classList.add('img-loaded')
    };
  }

}