import { Controller } from "stimulus"

export default class extends Controller {
  updateButton(event) {
    let [data, status, xhr] = event.detail;

    let html = document.createElement('span');
          html.innerHTML = xhr.response;

    event.target.replaceWith(html);
  }

  loadingButton(event) {
    const loading_text = "<i class='fa fa-spinner fa-spin'></i>";
    
    let html = document.createElement('span');
          html.innerHTML = loading_text;

    event.target.querySelector('.icon-container').innerHTML = loading_text;
  }
}