
import { Controller } from "stimulus"

export default class extends Controller {
  open(event){
    console.log(event.target);
    const current_user_name = event.target.getAttribute('data-current-user-name');
    const current_user_email = event.target.getAttribute('data-current-user-email');
    const whatsapp_text = event.target.getAttribute('data-whatsapp-text');

    Beacon('identify', {
      name: current_user_name || '',
      email: current_user_email || ''
    });

    Beacon('open');

    Beacon('prefill', {
      name: current_user_name,
      email: current_user_email,
      subject: 'Quiero solicitar un envío',
      text: whatsapp_text
    });


    $("#modal-window").modal('hide');

    if ($("#beacon-container #helpscout-helper-toast").length > 0){
      $("#beacon-container").find('#helpscout-helper-toast').find('.toast-body').find('p').html(whatsapp_text);
    }else{
      $('#helpscout-helper-toast').find('.toast-body').find('p').attr('data-clipboard-text', whatsapp_text);
      $('#helpscout-helper-toast').find('.toast-body').find('p').html(whatsapp_text);
      $("#helpscout-helper-toast").clone().appendTo("#beacon-container");
      $("body > #helpscout-helper-toast").remove();
    }

    setTimeout(function(){
      $('#helpscout-helper-toast').removeClass('d-none');
      $('#helpscout-helper-toast').toast('show');
    }, 1200);

    Beacon('on', 'close', () => {
      $('#helpscout-helper-toast').toast('hide');
    })
  }
}
