import { Controller } from 'stimulus'

import Splide from '@splidejs/splide'

export default class extends Controller {

  static targets = [ "thumbnail", "main", "photoSlider", "thumbnailSlider", "categorySlider", "homePageSlider", "instagramPostSlider", "jumboSlider", "productSlider" ]

  connect() {
    this.initSplide()
  }

  initSplide() {
    if(this.hasThumbnailSliderTarget){
      var secondarySlider = new Splide( this.thumbnailSliderTarget, {
        rewind      : true,
        fixedWidth  : 64,
        fixedHeight : 64,
        isNavigation: true,
        gap         : 10,
        pagination  : false,
        cover       : true,
        breakpoints : {
          '600': {
            fixedWidth  : 66,
            fixedHeight : 40,
          }
        }
      } ).mount();
      
      // Create the main slider.
      var primarySlider = new Splide( this.photoSliderTarget, {
        type: 'fade',
        heightRatio: 0,
        pagination: false,
        arrows: false,
        cover: false,
      } );
      
      // Set the thumbnails slider as a sync target and then call mount.
      primarySlider.sync( secondarySlider ).mount();
    }

    if(this.hasCategorySliderTarget){
      new Splide( this.categorySliderTarget, {
        type   : 'loop',
        pagination: false,
        cover: true,
        padding: {
          right: '5rem',
          left : '5rem',
        },
      } ).mount();
    }


    if(this.hasInstagramPostSliderTarget){
      var elms = this.instagramPostSliderTargets;

      for ( var i = 0, len = elms.length; i < len; i++ ) {
        new Splide( elms[ i ], {
          type : 'slide',
          cover: true,
          heightRatio: 1,
        } ).mount();        
      }
    }    

    if(this.hasHomePageSliderTarget){
      new Splide( this.homePageSliderTarget, {
        perPage: 6,
        height: '10rem',
        type: 'loop',
        cover: true,
        pagination: false,
        breakpoints: {
          '640': {
            perPage: 3
          },
          '480': {
            perPage: 2,
          }
        }
      } ).mount();
    }

    if(this.hasJumboSliderTarget){
      var elm = this.jumboSliderTarget;

      new Splide( elm, {
        type : 'loop',
        autoHeight: true,
      } ).mount();

    }  
    
    if(this.hasProductSliderTarget){
      var elm = this.productSliderTarget;

      new Splide( elm, {
        perPage: 4,
        type : 'loop',
        pagination: false,
        breakpoints: {
          '640': {
            perPage: 2
          },
          '480': {
            perPage: 1
          }
        },
        padding: {
          right: '2rem',
          left : '2rem',
        }
      } ).mount();        
    }      
  }

}