import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "button", "form", "container" ]

  connect() {
    this.initMercadopagoButton()
  }

  initMercadopagoButton() {
    const self = this;

    let script = document.createElement("script");
    
    script.src = `https://sdk.mercadopago.com/js/v2?t=${Date.now()}`;
    script.async = true;
    script.crossorigin = "anonymous";
    
    script.addEventListener("load", () => {
      self.renderMercadopagoButton()
    });
    
    document.body.appendChild(script); 
  }

  renderMercadopagoButton() {
    const self = this; 

    if (!self.hasButtonTarget){
      return false;
    }
    
    const buttonText = self.buttonTarget.getAttribute('data-checkout-text');
    
    const mp = new MercadoPago(self.buttonTarget.getAttribute('data-checkout-key'), {
      locale: 'es-AR'
    });

    const checkout = mp.checkout({
      preference: {
          id: self.buttonTarget.getAttribute('data-checkout-id')
      },
      render: {
          container: "#mp-button",
          label: buttonText,
        }
    });

    setTimeout(function(){
      $(self.containerTarget).find("#mp-button > button").addClass('btn btn-lg');
      $(self.containerTarget).find("#mp-button > button").css({"font-size":"1.1rem"});      
      $(self.containerTarget).find(".mp-btn-loader").remove();
      $(self.containerTarget).find(".mp-btn-container").removeClass('d-none');
    },1000);    

  }
}