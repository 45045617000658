import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "messageBody", "chatBox" ]

  connect() {
    this.initChatBox()
  }

  initChatBox() {
    this.chatBoxTarget.scrollTop = this.chatBoxTarget.scrollHeight;
    this.messageBodyTarget.focus();
  }

}